.form-block {
    .data-block {
        display: flex;
        padding-bottom: 10px;

        .data-items {
            margin-right: 25px;
            width: 230px;
        }

        .answer {
            position: relative;
            width: calc(100% - 230px;);

            .char-count {
                bottom: 15px;
                color: $color-orange;
                left: 15px;
                letter-spacing: 1px;
                padding: 0;
                position: absolute;
                text-transform: uppercase;
            }
        }

        .form-item {
            display: flex;
            flex-direction: column;
            position: relative;
        }

        input, textarea {
            background: none;
            border: 1px solid $color-primary;
            border-radius: 0;
            color: $color-primary;
            font-size: 1.3rem;
            height: 50px;
            margin: 10px 0;
            outline: none;
            padding: 10px 15px;
            width: 100%;
        }

        label {
            color: $color-primary;
            cursor: pointer;
            display: block;
            left: 15px;
            letter-spacing: 1px;
            margin: 0;
            padding: 0;
            pointer-events: none;
            position: absolute;
            text-transform: uppercase;
            top: -5px;
            z-index: 1;

            &:before {
                background: $color-green;
                content: '';
                display: block;
                height: 1px;
                left: -5%;
                position: absolute;
                top: 15px;
                width: 110%;
                z-index: -1;
            }
        }

        input.has-error {
            border-color: $color-orange;
            color: $color-orange;

            & + label {
                color: $color-orange;
            }
        }

        textarea {
            height: 190px;
            padding: 20px 15px;

            &::placeholder {
                font-size: 1.3rem;
                padding: 0;
            }
        }
    }

    .statement-item {
        align-content: flex-start;
        display: block;
        font-size: 1.3rem;
        height: 100%;
        line-height: 2rem;
        padding: 10px 0 10px 40px;
        position: relative;
        width: 100%;
    }

    // custom checkboxs
    input[type="checkbox"], .checkmark {
        left: 0;
        position: absolute;
        top: 10px;
    }

    input[type="checkbox"] {
        cursor: pointer;
        height: 20px;
        opacity: 0;
        width: 20px;
        z-index: 1;
    }

    .checkmark {
        border: 1px solid $color-primary;
        height: 20px;
        width: 20px;
    }

    input[type="checkbox"]:checked ~ .checkmark {
        height: 20px;
        width: 20px;

        &:before {
            background: $color-orange;
            content: '';
            display: block;
            height: 14px;
            left: 2px;
            position: absolute;
            top: 2px;
            width: 14px;
        }
    }

    .text-small {
        font-weight: 300;
        letter-spacing: 1px;
        padding-left: 40px;
    }

    input[type="submit"] {
        border-radius: 0;
        font-weight: 700;
        letter-spacing: normal;
        padding: 15px;
        width: 170px;

        &[disabled] {
            opacity: .5;
            pointer-events: none;
        }
    }
}

@media screen and (max-width: 1040px) {
    .form-block {
        .data-block {
            label {
                top: 0;

                &:before {
                    top: 10px;
                }
            }
        }
    }
}
