.regulation {
    background-image: url('images/backgrounds/bg_05.jpg');
    display: none;
    // display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    .wrapper {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 50px 0;
        position: relative;

        .section-subtitle {
            align-self: flex-start;
        }

        .close-icon {
            color: $color-primary;
            position: absolute;
            top: 90px;
            right: 0;
            transition: $link-transition;
            z-index: 2;

            &:active,
            &:focus,
            &:hover {
                color: $color-orange;
                transition: $link-transition;
            }
        }

        .text-container {
            display: block;
            width: 100%;
        }

        .reg-list {
            height: 70vh;
            list-style-type: decimal;
            margin: 0;
            overflow: scroll;
            padding: 0 150px 0 30px;
            position: relative;
            width: 100%;
        }

        // custom scrollbar
        .ps__rail-y {
            background-color: $color-primary;
            opacity: 1;
            width: 10px;

            .ps__thumb-y {
                background-color: $color-orange;
                border-radius: 0;
                right: 0;
                width: 10px;
            }
        }
    }
}

/* media screen 1400 1320 1040 840 720 560 320 */
@media screen and (max-width: 1400px) {
    .regulation {
        .wrapper {
            padding: 50px 30px;

            .close-icon {
                right: 30px;
            }

            .reg-list {
                padding: 0 120px 0 30px;
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .regulation {
        .wrapper {
            .close-icon {
                font-size: 3rem;
                top: 80px;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .regulation {
        .wrapper {
            display: block;
            padding: 0 20px;

            .reg-list {
                font-size: 1.4rem;
                line-height: 1.5;
                height: 100%;
                padding: 0 80px 0 30px;
            }

            .close-icon {
                right: 20px;
                top: 5px;
            }

            .text-container {
                height: calc(100vh - 100px);
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .regulation {
        .wrapper {
            .reg-list {
                padding: 0 40px 0 30px;
            }
        }
    }
}
