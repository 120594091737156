.rules {
    align-items: center;
    background-image: url('images/backgrounds/bg_02.jpg');
    display: flex;
    padding-bottom: 20px;

    .wrapper {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .section-subtitle {
            width: 50%;
        }

        .rules-block {
            background-color: $color-green;
            display: flex;
            flex-wrap: wrap;
            padding: 40px 10px 20px 10px;
            width: 50%;

            .rules-list {
                display: flex;
                flex-direction: column;
                list-style-type: none;
                margin: 0;
                width: 100%;

                .rules-item {
                    display: flex;
                    font-weight: 300;
                    justify-content: center;
                    padding: 15px 0;
                    position: relative;
                    width: 100%;

                    .number {
                        display: flex;
                        height: 70px;
                        justify-content: center;
                        margin-top: 8px;
                        padding-left: 30px;
                        width: 100px;

                        &:before {
                            background: $color-orange;
                            border-radius: 50%;
                            color: $color-green;
                            content: '';
                            display: block;
                            font-size: 1.1rem;
                            height: 16px;
                            left: 0;
                            line-height: 1.3rem;
                            margin: 0;
                            padding: 2px 0 0 3px;
                            position: absolute;
                            text-align: center;
                            top: 8px;
                            width: 16px;
                        }

                        &.step-1 {
                            &:before {
                                content: '1';
                            }
                        }

                        &.step-2 {
                            &:before {
                                content: '2';
                            }
                        }

                        &.step-3 {
                            &:before {
                                content: '3';
                            }
                        }

                        img {
                            display: block;
                            height: 50px;
                            width: auto;
                        }
                    }

                    .text-container {
                        margin: 0;
                        padding-left: 25px;
                        width: calc(95% - 70px);

                        strong {
                            font-weight: 700;
                            text-transform: uppercase;
                        }
                    }
                }

                .third {
                    .text-container {
                        display: flex;
                        flex-direction: column;

                        p {
                            margin: 0;
                        }

                        .award-list {
                            list-style-type: none;
                            padding-left: 25px;

                            .award-item {
                                position: relative;

                                &:before {
                                    background: $color-orange;
                                    content: '';
                                    display: block;
                                    height: 6px;
                                    left: -20px;
                                    position: absolute;
                                    top: 13px;
                                    transform: rotate(45deg);
                                    width: 6px;
                                }
                            }
                        }
                    }
                }
            }

            .text-results {
                font-size: 1.6rem;
                margin: 50px 0 0 0;
                padding-left: 30px;
                width: 100%;
            }

            .btn {
                display: inline-block;
                margin-left: 30px;
                width: auto;
            }

            .rules-link {
                margin-left: 25px;
                text-transform: uppercase;

                &:active,
                &:focus,
                &:hover {
                    color: $color-orange;
                }
            }
        }
    }
}

/* media screen 1400 1320 1040 840 720 560 320 */
@media screen and (max-width: 1300px) {
    .rules {
        .wrapper {
            .rules-block {
                padding: 30px 10px 20px 10px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .rules {
        .wrapper {
            .rules-block {
                .rules-list {
                    .rules-item {
                        .number:before {
                            padding: 3px 0 0 3px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .rules {
        .wrapper {
            .section-subtitle {
                width: 60%;
            }

            .rules-block {
                width: 60%;

                .rules-list {
                    padding: 20px;

                    .rules-item {
                        .number:before {
                            padding: 4px 0 0 3px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .rules {
        .wrapper {
            .section-subtitle,
            .rules-block {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .rules {
        .wrapper {
            .rules-block {
                padding: 5px;

                .rules-list {
                    .rules-item {
                        flex-direction: column;
                        font-size: 1.8rem;
                        padding: 10px 0;

                        .number {
                            height: 60px;
                            padding-left: 15px;

                            img {
                                transform: scale(.7);
                            }
                        }

                        .text-container {
                            padding-left: 0px;
                            width: 100%;
                        }
                    }
                }

                .text-results {
                    margin-top: 0px;
                    padding-left: 10px;
                }
            }
        }
    }
}
