body.show-agegate {
    .page-header.bg-section,
    .rules.bg-section,
    .awards.bg-section,
    .final.bg-section,
    .page-footer {
        display: none;
    }
}

.agegate {
    background-image: url('images/backgrounds/bg_05.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 100vh;
    position: static;
    width: 100%;

    .wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .link-logo {
            align-self: flex-end;
            padding-bottom: 60px;
            padding-top: 2rem;
            transform: translateX(-40%);

            .logo {
                display: block;
                height: auto;
                width: 400px;
            }
        }

        .container {
            display: flex;
            justify-content: center;
            width: 100%;

            .text-container,
            .agegate-form,
            .agegate-nein {
                width: 20%;
            }

            .agegate-nein {
                margin-right: 40px;

                strong {
                    color: $color-orange;
                    display: block;
                    font-size: 3.6rem;
                    line-height: 1.2;
                    padding-top: 1.5rem;
                }

                p {
                    font-size: 1.4rem;
                    line-height: 1.6;
                }

                a {
                    color: $color-orange;
                    text-decoration: underline;
                }

                &.is-hidden {
                    display: none;
                }
            }

            .text-container {
                margin-right: 40px;

                &.is-hidden {
                    display: none;
                }

                .text-small {
                    color: $color-orange;
                    font-size: 11px;
                    text-transform: uppercase;
                }
            }

            .agegate-form {
                padding-bottom: 100px;
            }

            .agegate-item {
                position: relative;

                input {
                    appearance: none;
                    background: none;
                    border: none;
                    color: $color-primary;
                    display: block;
                    font-size: 11.2rem;
                    font-weight: 700;
                    height: 11rem;
                    line-height: 13.5rem;
                    outline: none;
                    padding: 0 0 0 1rem;
                    position: relative;
                    width: 350px;

                    &::placeholder {
                        opacity: .5;
                    }

                    &:active,
                    &:focus,
                    &:hover {
                        outline: none;
                    }
                }

                label {
                    color: $color-primary;
                    display: block;
                    font-size: 11px;
                    height: 10px;
                    line-height: 2.8;
                    margin-bottom: 60px;
                    opacity: .5;
                    padding: 0 10px;
                    pointer-events: none;
                    position: relative;
                    text-transform: uppercase;

                    &:before {
                        background: $color-primary;
                        content: '';
                        display: block;
                        height: 10px;
                        position: absolute;
                        top: 11px;
                    }

                    &.dd:before,
                    &.mm:before,
                    &.rr:before {
                        transition: $link-transition;
                    }

                    &.dd:before {
                        left: 55px;
                        width: 150px;
                    }

                    &.mm:before {
                        left: 65px;
                        width: 140px;
                    }

                    &.rr:before {
                        left: 45px;
                        width: 285px;
                    }
                }

                input.is-active + label {
                    opacity: 1;
                    transition: $input-transition;

                    &.dd:before,
                    &.mm:before,
                    &.rr:before {
                        transition: $link-transition;
                    }
                }

                input::-webkit-inner-spin-button {
                    display: none;
                }

                input.error,
                input.has-error {
                    color: $color-orange;
                }

                input.error + label,
                input.has-error + label {
                    color: $color-orange;
                    opacity: 1;
                    transition: $link-transition;

                    &:before {
                        background: $color-orange;
                    }
                }

                .error-text {
                    color: $color-orange;
                    display: none;
                    font-size: 11px;
                    font-weight: 700;
                    left: 240px;
                    line-height: 1.3;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 300px;

                    .fa-times-circle {
                        padding-right: 5px;
                    }

                    a {
                        color: $color-orange;
                        text-decoration: underline;

                        &:active,
                        &:focus,
                        &:hover {
                            opacity: .5;
                        }
                    }
                }

                input.error ~ .error-text {
                    display: block;
                }

                label[for="rr"] + .error-text {
                    left: 360px;
                    top: 65%;
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .agegate {
        .wrapper {
            .agegate-form {
                width: 660px!important;
            }

            .link-logo {
                transform: translateX(-82%);
            }
        }
    }
}

@media (max-width: 1140px) {
    .agegate {
        .wrapper {
            display: block;

            .link-logo {
                display: block;
                padding: 4rem 0;
                transform: none;
            }

            .text-container,
            .agegate-nein {
                width: 200px!important;
            }

            .agegate-form {
                width: calc(100% - 240px)!important;
            }
        }
    }
}

@media (max-width: 960px) {
    .agegate {
        .wrapper {
            .container {
                display: block;

                .text-container,
                .agegate-nein {
                    float: left;
                    margin: 0!important;
                }

                .agegate-form {
                    float: right;
                    margin-left: 40px;
                    position: relative;

                    .agegate-item {
                        padding-top: 1rem;

                        label {
                            margin-bottom: 1rem;

                            &.dd:before {
                                width: 45px;
                            }

                            &.mm:before {
                                width: 35px;
                            }

                            &.rr:before {
                                width: 120px;
                            }
                        }

                        input {
                            font-size: 8rem;
                            line-height: 8rem;
                            height: 8rem;
                        }

                        input.error ~ .error-text {
                            margin: 4rem 0 0 1rem;
                            position: static;
                            transform: none;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .agegate {
        .wrapper {
            .link-logo {
                .logo {
                    width: 240px;
                }
            }

            .container {
                .text-container,
                .agegate-nein {
                    width: 100%!important;

                    p {
                        br {
                            display: none;
                        }
                    }
                }

                .agegate-form {
                    clear: both;
                    left: -10px!important;
                    width: 100%!important;

                    .agegate-item {
                        label {
                            &.dd:before {
                                width: 15px;
                            }

                            &.mm:before {
                                width: 5px;
                            }

                            &.rr:before {
                                width: 70px;
                            }
                        }

                        input {
                            font-size: 5rem;
                            line-height: 5rem;
                            height: 5rem;
                        }
                    }
                }
            }
        }
    }
}

/* media screen 1400 1320 1040 840 720 560 320 */
/*@media screen and (max-width: 1400px) {
    .agegate {
        .wrapper {
            .link-logo {
                transform: translateX(-20%);
            }
        }
    }
}

@media screen and (max-width: 1320px) {
    .agegate {
        .wrapper {
            .link-logo {
                transform: translateX(-15%);
            }

            .container {
                .agegate-item {
                    label {
                        &.dd:before {
                            width: 120px;
                        }

                        &.mm:before {
                            width: 115px;
                        }

                        &.rr:before {
                            width: 235px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .agegate {
        .wrapper {
            .link-logo {
                transform: translateX(-10%);
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .agegate {
        .wrapper {
            justify-content: flex-start;

            .link-logo {
                transform: none;
            }

            .container {
                justify-content: flex-start;

                .text-container {
                    width: 30%;
                }

                .agegate-item {
                    label {
                        &.dd:before {
                            width: 100px;
                        }

                        &.mm:before {
                            width: 95px;
                        }

                        &.rr:before {
                            width: 200px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .agegate {
        .wrapper {
            .container {
                .agegate-item {
                    .error-text {
                        width: 110px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .agegate {
        .wrapper {
            align-content: flex-start;

            .link-logo {
                padding: 0;

                .logo {
                    height: 50px;
                }
            }

            .container {
                flex-wrap: wrap;

                .text-container {
                    margin: 0;
                    width: 100%;

                    p {
                        margin: 0;
                    }

                    br {
                        display: none;
                    }
                }

                .agegate-form {
                    width: 100%;

                    .agegate-item {
                        &:first-child {
                            margin-top: 1.5rem;
                        }

                        input {
                            font-size: 4rem;
                            line-height: 4rem;
                            height: 4rem;
                            padding: 0;
                            width: 100%;

                            &::placeholder {
                                text-indent: 0;
                            }
                        }

                        label {
                            margin-bottom: 20px!important;
                            padding: 0;

                            &:before {
                                display: none;
                            }
                        }

                        .error-text {
                            width: 280px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .agegate {
        .wrapper {
            .link-logo {
                padding: 10px 0 10px 0!important;

                .logo {
                    height: 40px;
                }
            }

            .container {
                flex-wrap: wrap;

                .text-container {
                    width: 100%;

                    br {
                        display: none;
                    }
                }

                .agegate-form {
                    width: 100%;

                    .agegate-item {
                        .error-text {
                            margin-bottom: 5px;
                            margin-top: -10px;
                            position: static;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 640px) {
    .agegate {
        .wrapper {
            .link-logo {
                margin-top: 2rem;
                padding-bottom: 20px;
            }

            .container {
                .agegate-item {
                    input {
                        font-size: 6rem;
                        height: 6rem;
                        line-height: 6rem;

                        &::placeholder {
                            text-indent: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 420px) {
    .agegate {
        .wrapper {
            .container {
                .agegate-item {
                    label {
                        margin-bottom: 25px;
                    }
                }

                .text-container {
                    font-size: 1.5rem;
                    line-height: 1.6;
                }
            }
        }
    }
}

@media screen and (max-height: 360px) {
    .agegate {
        .wrapper {
            .container {
                .agegate-item {
                    input {
                        font-size: 3rem!important;
                        height: 3rem!important;
                        line-height: 2rem!important;

                        &::placeholder {
                            text-indent: 0;
                        }
                    }

                    label {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}*/
