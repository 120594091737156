.page-header {
    background-image: url('images/backgrounds/bg_01.jpg');
    border-left: 4px solid $color-orange;
    display: flex;
    height: 100vh;

    .wrapper {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        min-height: 100vh;
        position: relative;

        .menu {
            align-self: flex-start;
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
            width: 100%;

            .logo {
                align-self: flex-start;
                display: block;
                height: auto;
                width: 330px;
            }

            .menu-icon {
                cursor: pointer;
                display: none;
                height: 40px;
                position: absolute;
                right: 20px;
                top: 25px;
                width: 40px;
                z-index: 1;

                &.close {
                    right: 20px;
                    top: 25px;
                }
            }

            .menu-icon {
                color: $color-orange;

                span {
                    border-bottom: 5px solid $color-orange;
                    display: block;
                    height: 1px;
                    left: 50%;
                    position: absolute;
                    top: 60%;
                    transform: translate(-50%, -60%);
                    width: 40px;

                    &:before,
                    &:after {
                        border-top: 5px solid;
                        content: '';
                        display: block;
                        height: 1px;
                        position: absolute;
                        width: 40px;
                    }

                    &:before {
                        top: -12px;
                    }

                    &:after {
                        top: 12px;
                    }
                }

                &:hover {
                    color: $color-primary;

                    span {
                        border-color: $color-primary;
                    }
                }

                &.close {
                    color: $color-orange;

                    span {
                        left: 0px;
                        top: 20px;
                        transform: rotate(-45deg);

                        &:before {
                            border-top: 5px solid;
                            content: '';
                            display: block;
                            height: 1px;
                            position: absolute;
                            width: 40px;
                            left: 0px;
                            top: 0px;
                            transform: rotate(90deg);
                        }

                        &:after {
                            display: none;
                        }
                    }

                    &:hover {
                        color: $color-primary;
                    }
                }
            }

            .menu-list {
                align-self: center;
                display: flex;
                font-weight: 700;
                list-style: none;
                margin: 0;
                padding: 0;
                text-transform: uppercase;

                .menu-item {
                    margin: 20px 26px;
                    padding-bottom: 5px;

                    .link {
                        border-bottom: 2px solid transparent;
                        padding-bottom: 5px;

                        &:active,
                        &:focus,
                        &:hover {
                            border-bottom: 2px solid $color-orange;
                            transition: $link-transition;
                        }

                        &.is-inactive {
                            border-bottom: none;
                            cursor: not-allowed;
                            opacity: .5;
                        }
                    }
                }
            }
        }

        .container {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 50%;

            .text-container {
                display: flex;
                flex-wrap: wrap;

                .description {
                    margin: 0;
                    padding-left: 25px;
                    width: calc(100% - 90px);

                    .link {
                        color: $color-orange;
                        font-weight: 700;
                        text-decoration: none;
                        text-transform: uppercase;

                        &:active,
                        &:focus,
                        &:hover {
                            color: $color-primary;
                            transition: $link-transition;
                        }
                    }
                }

                .slider {
                    display: block;
                    margin-top: 10px;
                    position: relative;
                    width: 85px;

                    img {
                        display: block;
                        height: 100%;
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        transform: scale(.85);
                        transition: all .5s ease-out;
                        width: 100%;

                        &:first-child {
                            position: relative;
                        }

                        &.is-active {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                }

                .btn {
                    margin: 25px 0 0 110px;
                    white-space: nowrap;
                    width: auto;
                }
            }
        }

        .arrow {
            bottom: 50px;
            cursor: pointer;
            height: 25px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            transition: $link-transition;
            width: 30px;

            &:before {
                border: solid $color-orange;
                border-width: 0 0 1px 1px;
                content: "";
                cursor: pointer;
                height: 20px;
                left: 50%;
                position: absolute;
                pointer-events: none;
                top: 0;
                transform: translateX(-50%) rotate(-45deg);
                transition: $link-transition;
                width: 20px;
            }

            &:active,
            &:focus,
            &:hover {
                height: 35px;

                &:before {
                    border: solid $color-primary;
                    border-width: 0 0 1px 1px;
                    padding-bottom: 10px;
                }
            }
        }
    }
}

/* media screen 1400 1320 1040 840 720 560 320 */
@media screen and (max-width: 1400px) {
    .page-header {
        .wrapper {
            padding: 0 20px;
        }
    }
}

@media screen and (max-width: 1320px) {
    .page-header .wrapper {
        .menu {
            padding-right: 20px;

            .menu-list {
                .menu-item {
                    margin: 20px 25px;
                }
            }
        }

        .container {
            width: 50%;
        }
    }
}

@media screen and (max-width: 1200px) {
    .page-header .wrapper {
        .menu {
            padding-right: 20px;

            .logo {
                width: 300px;
            }

            .menu-list {
                .menu-item {
                    margin: 20px 20px;
                }
            }
        }

        .container {
            width: 60%;
        }
    }
}

@media screen and (max-width: 1040px) {
    .page-header {
        .wrapper {
            .container {
                .text-container {
                    .description {
                        br {
                            display: none;
                        }
                    }

                    .slider {
                        margin-top: 0;
                        width: 70px;
                    }
                }
            }

            .menu {
                .menu-icon,
                .menu-icon .close {
                    display: flex;
                }

                .menu-list {
                    background: $color-green;
                    display: none;

                    &.show {
                        display: flex;
                        font-size: 2rem;
                        font-weight: 300;
                        position: absolute;
                        flex-direction: column;
                        justify-content: flex-start;
                        top: 90px;
                        left: 0%;
                        padding: 0;
                        width: 100%;
                        z-index: 10;

                        .menu-item {
                            border-bottom: 1px solid rgba(255, 255, 255, .25);
                            text-align: center;
                            margin: 0;
                            padding: 0;
                            width: 100%;

                            &:first-child {
                                border-top: 1px solid rgba(255, 255, 255, .25);
                            }

                            a {
                                background: $color-green;
                                border: none;
                                display: block;
                                padding: 10px;
                                width: 100%;

                                &:focus,
                                &:hover {
                                    border: none;
                                    background: $color-orange;
                                }

                                &.is-inactive {
                                    color: rgba(233, 227, 199, .5);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .page-header {
        background-image: none;
        background-color: #071806;
        height: auto;
        min-height: 100vh;

        &:before {
            background-image: url('images/backgrounds/JM_1.jpg');
            background-position: top center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            height: 660px;
            position: absolute;
            top: 100px;
            width: calc(100% - 4px);
        }

        .wrapper {
            .menu {
                .logo {
                    max-width: 70%;
                }
            }

            .container {
                margin-top: 600px;
                position: static;
                width: 100%;

                .title {
                    font-size: 5rem;
                }

                .text-container {
                    .btn {
                        margin-left: 50%!important;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .page-header {
        .wrapper {
            .container {
                margin-top: 550px;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .page-header {
        .wrapper {
            .container {
                margin-top: 500px;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .page-header {
        .wrapper {
            .container {
                margin-top: 450px;
                width: 100%;
            }

            .menu {
                .logo {
                    max-width: 80%;
                }

                .menu-icon {
                    right: 20px;
                    top: 25px;
                }
            }
        }
    }
}

@media screen and (max-width: 420px) {
    .page-header {
        .wrapper {
            .container {
                margin-top: 420px;

                .text-container {
                    .description {
                        font-size: 1.6rem;
                        line-height: 1.5;
                        padding-left: 20px;
                    }
                }

                .title {
                    letter-spacing: .1rem;
                    font-size: 4rem;
                }
            }
        }
    }
}
