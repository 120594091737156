.page-footer {
    .wrapper {
        margin-top: -80px;
        position: relative;

        .link {
            padding: 0 10px 0 0;

            &:active,
            &:focus,
            &:hover {
                color: $color-orange;
            }

            i {
                position: relative;
                top: 4px;
            }
        }
    }
}

.final {
    align-items: center;
    background-image: url('images/backgrounds/bg_05.jpg');
    position: relative;
    display: flex;
    padding-bottom: 100px;

    &:before {
        background-image: url('images/backgrounds/JM_0.png');
        background-position: right center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
    }

    .wrapper {
        align-items: center;
        display: flex;

        .form-container {
            // display: none;
            display: block;
            height: 60%;
            position: relative;
            width: 65%;
        }

        .final-container {
            display: block;
            // display: none;
            height: 60%;
            position: relative;
            width: 65%;

            .section-subtitle {
                margin-top: 0;
                width: 50%;
            }

            .winners-list {
                height: 50vh;
                list-style-type: none;
                margin: 0;
                overflow: scroll;
                padding: 0 150px 0 0;
                position: relative;
                width: 100%;

                .winners-item {
                    .winner-name {
                        color: $color-orange;
                        font-size: 1.5rem;
                        font-weight: 700;
                        text-transform: uppercase;
                    }

                    .winner-text {
                        margin: 0 0 20px 0;
                    }
                }
            }

            // custom scrollbar
            .ps__rail-y {
                background-color: $color-primary;
                opacity: 1;
                width: 10px;

                .ps__thumb-y {
                    background-color: $color-orange;
                    border-radius: 0;
                    right: 0;
                    width: 10px;
                }
            }
        }
    }
}

/* media screen 1400 1320 1040 840 720 560 320 */
@media screen and (max-width: 1040px) {
    .final {
        overflow: hidden;

        &:before {
            right: -170px;
        }

        .wrapper {
            .final-container {
                .winners-list {
                    padding: 0 100px 0 0;
                }

                .section-subtitle {
                    width: 50%;
                }
            }
        }

        .form-container {
            .form-block {
                .data-block {
                    flex-direction: column;

                    .data-items {
                        width: 100%;
                    }

                    .form-item {
                        width: 100%;
                    }
                }
            }
        }
    }

    .page-footer {
        .wrapper {
            .link {
                i {
                    top: 2px;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .final {
        .wrapper {
            .final-container {
                .section-subtitle {
                    width: 280px;
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .final {
        &:before {
            display: none;
        }

        .wrapper {
            .final-container {
                width: 100%;
            }

            .form-container {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .final {
        .wrapper {
            .final-container {
                .section-subtitle {
                    width: 240px;
                }
            }
        }
    }
}
