.awards {
    align-items: center;
    background-image: url('images/backgrounds/bg_03.jpg');
    border-left: 4px solid $color-orange;
    display: flex;
    padding-bottom: 20px;

    .wrapper {
        align-content: center;
        display: flex;
        flex-direction: column;

        .container {
            align-items: center;
            display: flex;
            margin-bottom: 45px;

            .hd-icon {
                display: inline-block;
                height: auto;
                margin-right: 35px;
                width: 90px;

                img {
                    display: block;
                    height: auto;
                    width: 100%;
                }
            }
        }

        .text-small {
            letter-spacing: 2px;
        }

        .text-big {
            margin: 0;

            span {
                background: $color-green;
                padding: 0 2rem;

                mark {
                    background: $color-green;
                    color: $color-orange;
                }
            }
        }
    }
}

/* media screen 1400 1320 1040 840 720 560 320 */
@media screen and (max-width: 1040px) {
    .awards {
        .wrapper {
            .container {
                .hd-icon {
                    width: 70px;
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .awards {
        .wrapper {
            .text-big {
                span {
                    padding: 0 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .awards {
        .wrapper {
            .container {
                .section-subtitle {
                    width: 50%;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .awards {
        .wrapper {
            .text-big {
                span {
                    padding: 0 .75rem;
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .awards {
        .wrapper {
            .container {
                .section-subtitle {
                    width: 100%;
                }
            }

            .text-big {
                span {
                    letter-spacing: -.05rem;
                }
            }
        }
    }
}
