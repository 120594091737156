*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    background-image: url('images/backgrounds/bg_05.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    color: $color-primary;
    font-family: $font-primary;
    font-size: 2rem;
    font-weight: 300;
    line-height: 3.3rem;
}

.wrapper {
    margin: 0 auto;
    max-width: 1400px;
    width: 100%;
}

.bg-section {
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

.title {
    font-size: 6.6rem;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 6.6rem;
    margin: 0;
    text-transform: uppercase;
}

.subtitle {
    font-size: 3.3rem;
    font-weight: 700;
    margin: 15px 0 30px 0;
}

.section-subtitle {
    font-size: 3.3rem;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 4.4rem;
    position: relative;
    text-transform: uppercase;
    width: 330px;
    z-index: 1;

    &:before{
        background: $color-orange;
        content: '';
        display: block;
        height: 15px;
        left: -10px;
        position: absolute;
        top: 25px;
        width: 130px;
        z-index: -1;
    }
}

.text-small {
    font-size: 1.1rem;
    font-weight: 700;
}

.text-big {
    font-size: 8.6rem;
    font-weight: 700;
    line-height: 9.9rem;
}

.accent {
    background: none;
    color: $color-orange;
}

.link {
    color: $color-primary;
    text-decoration: none;
    transition: $link-transition;

    &:active,
    &:focus,
    &:hover {
        transition: $link-transition;
    }
}

.btn {
    appearance: none;
    background: transparent;
    border: 1px solid $color-orange;
    color: $color-primary;
    display: block;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 120px;

    &:active,
    &:focus,
    &:hover {
        border: 1px solid $color-primary;
        color: $color-orange;
        transition: $link-transition;
    }
}

/* media screen 1400 1320 1040 840 720 560 320 */
@media screen and (max-width: 1400px) {
    .wrapper {
        padding: 0 40px;
    }

    .text-big {
        font-size: 7.6rem;
        line-height: 8.9rem;
    }
}

@media screen and (max-width: 1320px) {
    html {
        font-size: 57.5%;
    }
}

@media screen and (max-width: 1200px) {
    html {
        font-size: 52%;
    }

    .text-big {
        font-size: 7.4rem;
        line-height: 8.4rem;
    }
}

@media screen and (max-width: 1040px) {
    html {
        font-size: 45%;
    }

    .section-subtitle {
        &:before{
            height: 10px;
            top: 18px;
            width: 100px;
        }
    }

    .text-big {
        font-size: 6.7rem;
        line-height: 8rem;
    }
}

@media screen and (max-width: 840px) {
    .text-big {
        font-size: 5.5rem;
        line-height: 6.2rem;
    }
}

@media screen and (max-width: 720px) {
    .text-big {
        font-size: 4rem;
        line-height: 4.5rem;
    }
}

@media screen and (max-width: 560px) {
    .wrapper {
        padding: 0 20px;
    }

    .section-subtitle {
        font-size: 2.5rem;
    }

    .text-big {
        // font-size: 3rem;
        // line-height: 2.8rem;
    }
}

@media screen and (max-width: 500px) {
    .text-big {
        font-size: 3rem;
        line-height: 3.6rem;
    }
}

@media screen and (max-width: 370px) {
    .text-big {
        font-size: 2.5rem;
        line-height: 2.8rem;
    }
}
